



































import Component from 'vue-class-component'
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import Multiselect from 'vue-multiselect'
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniSelect from './SygniSelect.vue';
import UserPresentation from '@/components/UserPresentation.vue';

@Component({
  components: { SygniCheckbox, SygniInputError, Multiselect, SygniRoundedButton, SygniRectButton, UserPresentation }
})
export default class SygniFundSelect extends SygniSelect {
  descriptionString(option: any): string {
    let description = option.type.toUpperCase();

    if(option?.address?.city) {
      description += `, ${option.address.city}`;
    }

    if(option?.address?.country) {
      description += `, ${option.address.country}`;
    }

    return description;
  }
  
  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isPersonalAccount() {
    return !!(this.activeUserData?.context?.name?.toLowerCase() === 'personal account')
  }
}

