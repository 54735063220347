var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm._v("Find your fund manager")]), _c('sygni-fund-select', {
    staticClass: "secondary",
    attrs: {
      "searchable": true,
      "options": _vm.availableFunds,
      "label": 'FUND NAME',
      "placeholder": 'Select your fund manager',
      "validation": _vm.$v.fundId
    },
    on: {
      "registerNewFund": _vm.registerNewFund
    },
    model: {
      value: _vm.fundId,
      callback: function callback($$v) {
        _vm.fundId = $$v;
      },
      expression: "fundId"
    }
  }), _c('sygni-rounded-button', {
    staticClass: "outline disabled secondary",
    attrs: {
      "disabled": !_vm.fundId || _vm.fundId === '',
      "arrow-icon": true
    },
    on: {
      "click": _vm.goNext
    }
  }, [_vm._v(_vm._s(_vm.isLegalEntityAlreadyUsed ? 'Go to Fund Manager' : 'Request to join'))]), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "cancel-button hoverable-a-element"
  }, [_vm._v("Cancel")])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }